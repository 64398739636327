/* eslint-disable react/prop-types */
import React from "react";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";

// Images
import ImgLeaf from "../Images/ImgLeaf";
import { mediaQueries } from "../../configs/_configSite";

const rotate = keyframes`
  0% {
    transform: rotate(-7.5deg);
  }
  50% {
    transform: rotate(7.5deg);
  }
  100% {
    transform: rotate(-7.5deg);
  }
`;

const Container = styled.section`
  z-index: 1;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s;
  @media (max-width: ${mediaQueries.md}px) {
    right: -100px;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    right: -200px;
  }
`;

const leafStyle = `
  display: inline-block;
`;

const LeavesRightOuter = ({ propRef }) => {
  const leaves = [
    {
      x: 80,
      y: -10,
      rotationStart: 10,
      origin: {
        x: 100,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      },
      zIndex: 4
    },
    {
      x: 80,
      y: 20,
      rotationStart: 145,
      origin: {
        x: 400,
        y: 225
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      },
      zIndex: 5
    },
    {
      x: 90,
      y: -10,
      rotationStart: 15,
      origin: {
        x: 125,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      }
    },
    {
      x: 90,
      y: 20,
      rotationStart: 15,
      origin: {
        x: 125,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      }
    },
    {
      x: 90,
      y: 50,
      rotationStart: 15,
      origin: {
        x: 125,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      }
    },
    {
      x: 90,
      y: 70,
      rotationStart: 15,
      origin: {
        x: 125,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      }
    },
    {
      x: 80,
      y: 70,
      rotationStart: 15,
      origin: {
        x: 125,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      },
      zIndex: 4
    },
    {
      x: 80,
      y: 47.5,
      rotationStart: 70,
      origin: {
        x: 375,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 11
      },
      zIndex: 5
    }
  ];

  return (
    <Container ref={propRef}>
      {leaves.map(leaf => (
        <div
          key={`${leaf.x}${leaf.y}`}
          css={css`
            ${leafStyle}
            position: absolute;
            pointer-events: auto;
            ${
              typeof leaf.zIndex === "undefined"
                ? "z-index: 3;"
                : `z-index: ${leaf.zIndex};`
            }
            left: ${leaf.x}%;
            top: ${leaf.y}%;
            transform-origin: ${leaf.origin.x}px ${leaf.origin.y}px;
            animation: ${leaf.animation.type} ${leaf.animation.speed}s
              ${leaf.animation.ease} infinite;
          `}
        >
          <ImgLeaf
            cssProp={`
              transform: rotate(${leaf.rotationStart}deg) !important;
            `}
          />
        </div>
      ))}
    </Container>
  );
};

export default LeavesRightOuter;
